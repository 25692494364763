import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';
import { IdApiInterface } from '@environments/assets/environment.interface';

export const idApiTargets: EnvironmentTargetInterface = {
  build: {
    shopStewardWebClientId: '', // https://id.fagforbundet.build/clients/oauth/<uuid>
    baseUrl: 'https://api.id.fagforbundet.build',
  } as IdApiInterface,
  production: {
    shopStewardWebClientId: '2c143a45-f3c2-4c46-a8a7-985cac8f0af6',
    baseUrl: 'https://api.id.fagforbundet.no',
  } as IdApiInterface,
  staging: {
    shopStewardWebClientId: 'd406e5f9-d1de-49ff-8823-21aec3ed4b21',
    baseUrl: 'https://api.id.fagforbundet.dev',
  } as IdApiInterface,
};
