import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';

export const filesApiTargets: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://api.filer.fagforbundet.no',
  },
  staging: {
    baseUrl: 'https://api.filer.fagforbundet.dev',
  },
};
