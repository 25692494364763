import { EnvironmentTargetInterface } from '@environments/assets/environment-target.interface';
import { EnvironmentInterface } from '@environments/assets/environment.interface';

export const cmsApiTargets: EnvironmentTargetInterface = {
  production: {
    baseUrl: 'https://www1.fagforbundet.no/minside',
  } as EnvironmentInterface,
  staging: {
    baseUrl: 'https://www1.fagforbundet.no/minsidenew',
  } as EnvironmentInterface,
};
